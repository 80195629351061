/*=========================================================================================
  File Name: moduleQuestionMutations.js
  Description: Question Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import defaultState from "./moduleProjectState";

export default {
  reset: () => {},

  SET_STATE(state, text) {
    const JSONparsed = JSON.parse(text);
    state.questions = JSONparsed.questions;
    state.classification = JSONparsed.classification;
    state.valueReflection = JSONparsed.valueReflection;
    state.projectReflection = JSONparsed.projectReflection;
    state.terms = JSONparsed.terms;
    state.kb_categories = JSONparsed.kb_categories;

    state.question_filter = JSONparsed.question_filter;

    state.countQuestionStarred = JSONparsed.countQuestionStarred;
    state.countQuestionAnswered = JSONparsed.countQuestionAnswered;
    state.countQuestionUnanswered = JSONparsed.countQuestionUnanswered;
    state.countAllQuestions = JSONparsed.countAllQuestions;
    state.meta = JSONparsed.meta;
    state.project = JSONparsed.project;
    state.questionSearchQuery = JSONparsed.questionSearchQuery;
    state.classificationFilter = JSONparsed.classificationFilter;
    state.categoryFilter = JSONparsed.categoryFilter;
    state.classificationSearchQuery = JSONparsed.classificationSearchQuery;
    state.glossarSearchQuery = JSONparsed.glossarSearchQuery;
    state.glossarFilter = JSONparsed.glossarFilter;
    state.favoritesFilter = JSONparsed.favoritesFilter;

    state.showValueReflection = JSONparsed.showValueReflection;
    state.showProfileInOverview = JSONparsed.showProfileInOverview;
    state.showProjectReflection = JSONparsed.showProjectReflection;
    state.projectRefletionNote = JSONparsed.projectRefletionNote;
    state.showCardsInOverview = JSONparsed.showCardsInOverview;
    state.projectCreated = JSONparsed.projectCreated;
    state.projectCompleted = JSONparsed.projectCompleted;
  },
  DELETE_PROJECT(state, text) {
    // Reset complete state of app to defaults.
    Object.assign(state, window.structuredClone(defaultState));
  },
  DELETE_PROJECT_SELECTIVE(state, text) {
    state.questions.forEach((question) => {
      question.answer.options.forEach((option) => {
        option.selected = false;
      });
      question.answer.answer.length = 0;
      question.isStarred = false;
      question.isAnswered = false;
    });
    state.classification.forEach((category) => {
      category.selected = false;
      category.points = 0;
      category.onePointAnswers.length = 0;
      category.twoPointAnswers.length = 0;
      category.maxselected = false;
      category.maxPoints = 0;
      category.relevance = 0;
      category.implications.length = 0;
      category.answers.length = 0;
      category.isStarred = false;
    });

    state.valueReflection.length = 13;
    state.valueReflection.forEach((value) => {
      value.list = value.baseList;
      value.x = 0;
      value.y = 0;
    });
    state.showValueReflection = false;

    state.projectReflection.forEach((step) => {
      step.reflection_questions.forEach((question) => {
        question.checked = false;
      });
    });
    state.question_filter.length = 0;
    state.question_filter.push("all");
    state.countQuestionStarred = 0;
    state.countQuestionAnswered = 0;
    state.countQuestionUnanswered = 0;
    state.countAllQuestions = 0;
    state.project.nameApplicant = "";
    state.project.nameProject = "";
    state.project.wantedResult = "";
    state.project.field = "";
    state.project.targetGroup = "";
    state.project.projectDescription = "";
    state.questionSearchQuery = "";
    state.classificationFilter = ["abc"];
    state.categoryFilter = ["abc"];
    state.classificationSearchQuery = "";
    state.glossarSearchQuery = "";
    state.showProfileInOverview = false;
    state.showValueReflection = false;
    state.showProjectReflection = false;
    state.projectRefletionNote = "";
    state.showCardsInOverview = false;
    state.projectCreated = false;
    state.projectCompleted = false;
    state.showTutorialProfile = true;
    state.showTutorialSurvey = true;
    state.showTutorialValueReflection = true;
    state.showTutorialProjectReflection = true;
  },
  SET_PROJECT_META(state, form) {
    state.project.nameApplicant = form.nameApplicant;
    state.project.nameProject = form.nameProject;
    state.project.wantedResult = form.wantedResult;
    state.project.field = form.field;
    state.project.targetGroup = form.targetGroup;
    state.project.projectDescription = form.projectDescription;
    // state.project = form
  },
  SET_QUESTION_SEARCH_QUERY(state, query) {
    state.questionSearchQuery = query;
  },
  SET_GLOSSAR_SEARCH_QUERY(state, query) {
    state.glossarSearchQuery = query;
  },

  SET_QUESTIONS(state, questions) {
    state.questions = questions;
  },
  SET_TAGS(state, tags) {
    state.questionTags = tags;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },

  TOGGLE_IS_QUESTION_STARRED(state, payload) {
    if (payload.value) state.countQuestionStarred++;
    else state.countQuestionStarred--;
    state.questions.find(
      (question) => question.id === payload.questionID
    ).isStarred = payload.value;
  },

  TOGGLE_IS_CATEGORY_STARRED(state, payload) {
    state.classification.find(
      (category) => category.id === payload.categoryId
    ).isStarred = payload.value;
    if (state.favoritesFilter.includes(payload.categoryId)) {
      let index = state.favoritesFilter.indexOf(payload.categoryId);
      state.favoritesFilter.splice(index, 1);
    } else {
      state.favoritesFilter.push(payload.categoryId);
    }
  },

  // If your process of fetching is different than ours. Please update action and mutation
  // Maybe this mutation is redundant for you. Feel free to remove it.

  ADD_ANSWER_TO_QUESTION(state, payload) {
    //Get Index of the question, a answer should be added or deleted
    let question_index = state.questions.findIndex(
      (question) => question.id === payload.questionId
    );
    //Wenn Radio, dann answer array leeren und neue Antwort rein, da eh nur eine Antwort möglich
    if (payload.isRadio) {
      state.questions[question_index].answer.answer.length = 0;
      let answer = {
        optionId: payload.optionId,
        name: payload.name,
        points: payload.points,
      };
      state.questions[question_index].answer.answer.push(answer);
    } else {
      let index = state.questions[question_index].answer.answer.findIndex(
        (option) => option.optionId === payload.optionId
      );

      if (index >= 0) {
        state.questions[question_index].answer.answer.splice(index, 1);
      } else {
        let answer = {
          optionId: payload.optionId,
          name: payload.name,
          points: payload.points,
        };
        state.questions[question_index].answer.answer.push(answer);
      }
    }
    if (
      state.questions[question_index].answer.answer.length === 1 &&
      !state.questions[question_index].isAnswered
    ) {
      state.questions[question_index].isAnswered = true;
      state.countQuestionAnswered += 1;
    }
    if (state.questions[question_index].answer.answer.length === 0) {
      state.questions[question_index].isAnswered = false;
      state.countQuestionAnswered -= 1;
    }
    state.countQuestionUnanswered =
      state.questions.length - state.countQuestionAnswered;
  },

  DELETE_ALL_ANSWERS_WITH_PAYLOAD_ID_FROM_CATEGORY(state, payload) {
    //At first delete old values from every category where the questionId is included in the answers
    if (payload.isRadio) {
      deleteAnswerWithQuestionID_Radio();
      addAnswerWithQuestionID_Radio();
    }
    function deleteAnswerWithQuestionID_Radio() {
      state.classification.forEach((category) => {
        let answerIndex = category.answers.findIndex(
          (answer) => answer.questionId === payload.questionId
        );
        if (answerIndex != -1) {
          category.answers.splice(answerIndex, 1);
        }
      });
    }
    function addAnswerWithQuestionID_Radio() {
      if (payload.points != null) {
        for (const [key, value] of Object.entries(payload.points)) {
          let answer = {
            questionId: payload.questionId,
            questionInquiry: payload.question_inquiry,
            options: [
              { optionId: payload.optionId, name: payload.name, points: value },
            ],
          };
          state.classification[key - 1].answers.push(answer);
          state.classification[key - 1].points += value;
        }
      }
    }
    if (!payload.isRadio) {
      if (payload.points != null) {
        for (const [key, value] of Object.entries(payload.points)) {
          let index = state.classification[key - 1].answers.findIndex(
            (answer) => answer.questionId === payload.questionId
          );
          if (index === -1 && payload.selected === true) {
            let answer = {
              questionId: payload.questionId,
              questionInquiry: payload.question_inquiry,
              options: [
                {
                  optionId: payload.optionId,
                  name: payload.name,
                  points: value,
                },
              ],
            };
            state.classification[key - 1].answers.push(answer);
            state.classification[key - 1].points += value;
          } else {
            let optionIndex = state.classification[key - 1].answers[
              index
            ].options.findIndex(
              (option) => option.optionId === payload.optionId
            );
            if (optionIndex === -1 && payload.selected === true) {
              let option = {
                optionId: payload.optionId,
                name: payload.name,
                points: value,
              };
              state.classification[key - 1].answers[index].options.push(option);
              state.classification[key - 1].points += value;
            }
            if (optionIndex != -1 && payload.selected === false) {
              state.classification[key - 1].answers[index].options.splice(
                optionIndex,
                1
              );
              state.classification[key - 1].points -= value;
            }
            if (
              state.classification[key - 1].answers[index].options.length === 0
            ) {
              state.classification[key - 1].answers.splice(index, 1);
            }
          }
        }
      }
    }
  },

  UPDATE_CATEGORY_ONE_TWO_POINT_ANSWERS(state) {
    state.classification.forEach((category) => {
      category.onePointAnswers.length = 0;
      category.twoPointAnswers.length = 0;
      category.answers.forEach((answer) => {
        answer.options.forEach((option) => {
          let questionId = answer.questionId;
          let optionName = option.name;
          let optionId = option.optionId;
          let points = option.points;
          let payload = {
            questionId: questionId,
            optionName: optionName,
            optionId: optionId,
            points: points,
          };
          switch (option.points) {
            case 1:
              category.onePointAnswers.push(payload);

              break;

            case 2:
              category.twoPointAnswers.push(payload);
              break;
          }
        });
      });
    });
  },

  UPDATE_CATEGORY_MAX_ANSWERS_MAX_POINTS(state) {
    state.questions.forEach((question) => {
      question.answer.options.forEach((option) => {
        if (option.points) {
          for (const [key, value] of Object.entries(option.points)) {
            if (value) {
              if (
                state.classification[key - 1].maxAnswers.includes(question.id)
              ) {
                // no op here
              } else {
                state.classification[key - 1].maxAnswers.push(question.id);
              }
            }
          }
        }
      });
    });
    state.classification.forEach((category) => {
    });
  },

  UPDATE_CATEGORY_IMPLICATIONS(state, payload) {
    state.classification.forEach((category) => {
      category.implications.length = 0;
      category.onePointAnswers.length = 0;
      category.twoPointAnswers.length = 0;
      category.answers.forEach((answer) => {
        let maxPoints = answer.options.reduce(function (prev, current) {
          return prev.points > current.points ? prev : current;
        });
        let implicationEntry = {
          questionId: answer.questionId,
          implication: maxPoints,
        };
        category.implications.push(implicationEntry);
        switch (maxPoints) {
          case 1:
            category.onePointAnswers.push(implicationEntry);

            break;

          case 2:
            category.twoPointAnswers.push(implicationEntry);
            break;
        }
      });
    });
  },

  UPDATE_ANSWER(state, payload) {
    // Answer ist always a array.
    this.commit("survey/ADD_ANSWER_TO_QUESTION", payload);
    this.commit(
      "survey/DELETE_ALL_ANSWERS_WITH_PAYLOAD_ID_FROM_CATEGORY",
      payload
    );
    this.commit("survey/UPDATE_CATEGORY_IMPLICATIONS", payload);
    if (state.countQuestionAnswered === state.questions.length) {
      state.showProfileInOverview = true;
    } else {
      state.showProfileInOverview = false;
    }
  },

  SET_CHECKBOX_QUESTION(state, payload) {
    let question_index = state.questions.findIndex(
      (question) => question.id === payload.question_id
    );
    let index = state.questions[question_index].answer.options.findIndex(
      (option) => option.optionId === payload.option_id
    );
    state.questions[question_index].answer.options[index].selected =
      payload.checked;
  },
  SET_RADIO_QUESTION(state, payload) {
    let question_index = state.questions.findIndex(
      (question) => question.id === payload.question_id
    );
    let index = state.questions[question_index].answer.options.findIndex(
      (option) => option.optionId === payload.option_id
    );
    if (state.questions[question_index].answer.options[index].selected === true)
      { /* no op */ }
    else
      state.questions[question_index].answer.options[index].selected =
        payload.checked;

    state.questions[question_index].answer.options.forEach((option) => {
      if (option.optionId !== payload.option_id) option.selected = false;
    });
  },

  UPDATE_FILTER_QUESTION(state, payload) {
    state.questions.find((mail) => mail.id === payload.id).filter =
      payload.filters;
  },
  UPDATE_QUESTION_FILTER(state, payload) {
    state.question_filter = [payload.value];
  },

  UPDATE_CATEGORY_FILTER(state, payload) {
    state.categoryFilter.length = 0;
    state.categoryFilter.push(payload.value);
  },

  UPDATE_GLOSSAR_FILTER(state, payload) {
    if (state.glossarFilter.includes(payload.value)) {
      let index = state.glossarFilter.indexOf(payload.value);
      state.glossarFilter.splice(index, 1);
    } else {
      if (payload.value === 1) {
        state.glossarFilter.length = 0;
        state.glossarFilter.push(payload.value);
      } else {
        let indexAlle = state.glossarFilter.indexOf(1);
        if (indexAlle > -1) {
          state.glossarFilter.splice(indexAlle, 1);
        }
        state.glossarFilter.push(payload.value);
      }
    }
    if (state.glossarFilter.length === 0) state.glossarFilter.push(1);
  },

  UPDATE_PROJECT_REFLECTION_NOTE(state, payload) {
    state.projectRefletionNote = payload.note;
  },

  // MUTATIONS FOR VALUE REFLECTION
  UPDATE_START_DRAG(state, payload) {
    const itemIndex = state.valueReflection.findIndex(
      (value) => value.title === payload.title
    );
    state.valueReflection[itemIndex].list = payload.list;
    state.valueReflection[itemIndex].x = payload.x;
    state.valueReflection[itemIndex].y = payload.y;
    state.valueReflection[itemIndex].layerX = payload.layerX;
    state.valueReflection[itemIndex].layerY = payload.layerY;
  },
  UPDATE_DROP(state, payload) {
    const itemIndex = state.valueReflection.findIndex(
      (value) => value.title === payload.title
    );
    state.valueReflection[itemIndex].list = payload.list;
    state.valueReflection[itemIndex].x = payload.x;
    state.valueReflection[itemIndex].y = payload.y;
    state.valueReflection[itemIndex].percentLeft = payload.percentLeft;
    state.valueReflection[itemIndex].percentTop = payload.percentTop;
    let count = 0;
    state.valueReflection.forEach((value) => {
      if (value.list === 2) {
        count++;
      }
    });
    if (count > 0) state.showValueReflection = true;
    else state.showValueReflection = false;
  },

  ADD_VALUE(state, payload) {
    state.valueReflection.push(payload);
  },
  DELETE_VALUE(state, payload) {
    let index = state.valueReflection.findIndex((value) => value.id == payload);
    state.valueReflection.splice(index, 1);
  },

  UPDATE_VALUE_NOTE(state, payload) {
    const itemIndex = state.valueReflection.findIndex(
      (value) => value.id === payload.id
    );
    state.valueReflection[itemIndex].note = payload.note;
  },

  RESET_ALL_VALUES(state, payload) {
    state.valueReflection.length = 13;
    state.valueReflection.forEach((value) => {
      value.list = value.baseList;
      value.x = 0;
      value.y = 0;
    });

    state.showValueReflection = false;
  },

  RESET_ALL_PROJECT_REFLECTION(state) {
    state.projectReflection.forEach((step) => {
      step.reflection_questions.forEach((question) => {
        question.checked = false;
      });
    });
  },

  SET_ACTIVE_VALUE(state, item) {
    let valueItemIndex = state.valueReflection.findIndex(
      (value) => value.id === item.id
    );
    if (state.valueReflection[valueItemIndex].isActive)
      state.valueReflection[valueItemIndex].isActive = false;
    else state.valueReflection[valueItemIndex].isActive = true;
  },

  SET_ALL_VALUES_INACTIVE(state) {
    state.valueReflection.forEach((value) => {
      value.isActive = false;
    });
  },

  SET_CHECKBOX(state, payload) {
    // Check question identified in payload
    let stepIndex = state.projectReflection.findIndex(
      (step) => step.step_id === payload.step_id
    );
    let questionIndex = state.projectReflection[
      stepIndex
    ].reflection_questions.findIndex(
      (question) => question.id === payload.question_id
    );
    state.projectReflection[stepIndex].reflection_questions[
      questionIndex
    ].checked = payload.checked;

    // Count checked questions
    let countQuestions = 0;
    state.projectReflection.forEach((step) => {
      step.reflection_questions.forEach((question) => {
        if (question.checked) {
          countQuestions++;
        }
      });
    });

    // Check, if at least one step has been finished
    let atLeastOneStepHasBeenFinished = false;
    state.projectReflection.forEach((step) => {
      let step_finish = true;
      step.reflection_questions.forEach((question) => {
        if (
          question.cat === "none" ||
          state.favoritesFilter.includes(question.cat)
        ) {
          if (!question.checked) {
            step_finish = false;
          }
        }
      });
      if (step_finish) {
        atLeastOneStepHasBeenFinished = true;
      }
    });

    state.showProjectReflection = atLeastOneStepHasBeenFinished === true;
    state.oneProjectReflectionQuestionAnswered = countQuestions > 0;
  },

  SET_FAVORITES(state, payload) {
    if (state.favoritesFilter.includes(payload.categoryId)) {
      let index = state.favoritesFilter.indexOf(payload.categoryId);
      state.favoritesFilter.splice(index, 1);
    } else {
      state.favoritesFilter.push(payload.categoryId);
    }
  },

  SET_SHOW_CARDS_IN_OVERVIEW(state) {
    state.showCardsInOverview = true;
  },

  SET_PROJECT_CREATED(state) {
    state.projectCreated = true;
  },

  SET_PROJECT_COMPLETED(state) {
    state.projectCompleted = true;
  },
  
  SET_TUTORIAL_PROFILE: (state) => {
    state.showTutorialProfile = false;
  },
  SET_TUTORIAL_SURVEY: (state) => {
    state.showTutorialSurvey = false;
  },

  SET_TUTORIAL_VALUE_REFLECTION: (state) => {
    state.showTutorialValueReflection = false;
  },

  SET_TUTORIAL_PROJECT_REFLECTION: (state) => {
    state.showTutorialProjectReflection = false;
  },
};
