/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import VuexReset from "@ianwalter/vuex-reset";

Vue.use(Vuex);

import moduleSurvey from "./survey/moduleSurvey";

const vuexPersist = new VuexPersist({
  key: "elsi-sat",
  storage: window.localStorage,
});

export default new Vuex.Store({
  modules: {
    survey: moduleSurvey,
  },
  plugins: [vuexPersist.plugin, VuexReset()],
  strict: process.env.NODE_ENV !== "production",
});
