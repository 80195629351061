/*=========================================================================================
==========================================================================================*/

export default {
  setState({ commit }, text) {
    commit("SET_STATE", text);
  },

  setProjectMeta({ commit }, form) {
    commit("SET_PROJECT_META", form);
  },

  setQuestionSearchQuery({ commit, dispatch }, query) {
    commit("SET_QUESTION_SEARCH_QUERY", query);
  },
  setCheckboxQuestion({ commit }, payload) {
    commit("SET_CHECKBOX_QUESTION", payload);
  },
  setRadioQuestion({ commit }, payload) {
    commit("SET_RADIO_QUESTION", payload);
  },
  // Toggle isStarred flag in mail
  toggleIsStarred({ commit, dispatch }, payload) {
    commit("TOGGLE_IS_QUESTION_STARRED", payload);
    //dispatch('fetchMeta')
  },

  toggleIsStarredCategory({ commit, dispatch }, payload) {
    commit("TOGGLE_IS_CATEGORY_STARRED", payload);
    //dispatch('fetchMeta')
  },

  // Commit filter updates
  updateFilterQuestions({ commit }, payload) {
    commit("UPDATE_FILTER_QUESTIONS", payload);
  },

  // Fetch Question Meta
  fetchMeta({ commit, state, getters }) {
    const filtered_questions_ids = getters.getFilteredQuestionsIDs;

    // Initialize blank object.
    let meta = {
      inbox: 0,
      answered: 0,
      starred: 0,
      trashed: 0,
    };

    // For each entry set folder and label.
    state.mails.forEach((mail) => {
      if (
        filtered_questions_ids.length > 0 &&
        !filtered_questions_ids.includes(mail.id)
      ) {
        return;
      }

      // Todo usta: Centralize the logic that determines labels and folders!
      // Folders (mutually exclusive, i.e. either, or).
      if (mail.answer.answer == "" && !mail.isTrashed) {
        meta.inbox++;
      } else if (mail.answer.answer !== "" && !mail.isTrashed) {
        meta.answered++;
      }

      // Labels.
      mail.isStarred ? meta.starred++ : "";
      mail.isTrashed ? meta.trashed++ : "";
    });

    commit("SET_META", meta);
  },
};
