/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  // Electron works only in hash mode.
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },

  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          name: "home",
          component: () => import("@/views/Home.vue"),
        },
        {
          path: "/about",
          name: "about",
          component: () => import("@/views/About.vue"),
        },
        {
          path: "/valueReflection",
          name: "valueReflection",
          component: () =>
            import("./views/apps/valueReflection/ValueReflection.vue"),

          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "ValueReflection", active: true },
            ],
            pageTitle: "Wertereflexion",
          },
        },
        {
          path: "/uebersicht",
          name: "uebersicht",
          component: () =>
            import("./views/apps/overview/ProjectOverview.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Uebersicht", active: true },
            ],
            pageTitle: "Projektübersicht",
          },
        },
        {
          path: "/reflexion",
          name: "reflexion",
          component: () =>
            import("./views/apps/reflection/ProjectReflection.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Reflexion", active: true },
            ],
            pageTitle: "Projektreflexion",
          },
        },
        {
          path: "/datenschutz",
          name: "datenschutz",
          component: () => import("./views/Datenschutz.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Datenschutz", active: true },
            ],
            pageTitle: "Datenschutz",
          },
        },
        {
          path: "/impressum",
          name: "impressum",
          component: () => import("./views/Impressum.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Impressum", active: true },
            ],
            pageTitle: "Impressum",
          },
        },
        {
          path: "/hinweise",
          name: "hinweise",
          component: () => import("./views/Hinweise.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Hinweise", active: true },
            ],
            pageTitle: "",
          },
        },
        {
          path: "/knowledge-base",
          redirect: "/knowledge-base/all",
        },
        {
          path: "/knowledge-base/:filter",
          name: "knowledge-base",
          component: () => import("./views/Glossar.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Glossar", active: true },
            ],
            pageTitle: "",
            rule: "editor",
          },
        },
        {
          path: "/survey",
          redirect: "/survey/all",
        },
        {
          path: "/survey/:filter",
          name: "survey",
          component: () => import("./views/apps/survey/Survey.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Fragebogen", active: true },
            ],
            pageTitle: "",
            rule: "editor",
            parent: "survey",
          },
        },
        {
          path: "/profile",
          redirect: "/profile/abc",
        },
        {
          path: "/profile/:filter",
          name: "profile",
          component: () => import("./views/apps/profile/Profile.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "ELSI-Profil", active: true },
            ],
            pageTitle: "",
            rule: "editor",
            parent: "profile",
          },
        },
        { path: "/elsi_cards", name: "elsi_cards", redirect: "elsi_cards/abc" },
        {
          path: "/elsi_cards/:filter",
          name: "elsi_cards",
          component: () => import("./views/apps/elsi_cards/ElsiCards.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "ELSI-Karten", active: true },
            ],
            pageTitle: "",
            rule: "editor",
            parent: "elsi_cards",
          },
        },
      ],
    },

    {
      // =============================================================================
      // FULL PAGE LAYOUTS
      // =============================================================================
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================

        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
        },
      ],
    },

    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404",
    },
  ],
});
// router.afterEach(to => {
//     localStorage.setItem(LS_ROUTE_KEY, to.name);
//   });
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
