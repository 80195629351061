import state from "./moduleProjectState";
import mutations from "./moduleSurveyMutations";
import actions from "./moduleSurveyActions";
import getters from "./moduleSurveyGetters";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
