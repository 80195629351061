<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import themeConfig from '@/../themeConfig.js';

export default {
    //A watcher is a special Vue.js feature that allows you to spy on one property of the component state, and run a function when that property value changes.
    watch: {
        '$store.state.theme'(val) {
            this.toggleClassInBody(val)
        }
    },
    methods: {
        toggleClassInBody(className) {
            if (className == 'dark') {
                if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
                document.body.classList.add('theme-dark')
            }
            else if (className == 'semi-dark') {
                if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
                document.body.classList.add('theme-semi-dark')
            }
            else {
                if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
                if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
            }
        },
        handleWindowResize() {
            // this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
        },
        handleScroll() {
            // this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
        }
    },
    mounted() {
        this.toggleClassInBody(themeConfig.theme)
        // this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
    },
    async created() {
        window.addEventListener('resize', this.handleWindowResize)
        window.addEventListener('scroll', this.handleScroll)
    },
    destroyed() {
        window.removeEventListener('resize', this.handleWindowResize)
        window.removeEventListener('scroll', this.handleScroll)
    },
}

</script>


<style lang="scss">
.router-view {
    padding: 0px !important;
    max-width: auto;
}
</style>
