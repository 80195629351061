/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue';
import App from './App.vue';

import 'bootstrap-vue'
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Vuesax Component Framework
import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(BootstrapVue, Vuesax)

// Theme Configurations
import '../themeConfig.js';


// Globally Registered Components
//import './globalComponents.js'


// Styles: SCSS
// Import done via vue.config.js
// import './assets/scss/main.scss'


// Vue Router
import router from './router';


// Vuex Store
import store from './store/store';

// Vuexy Admin Filters
//import './filters/filters'

// Feather font icon
require('./assets/css/iconfont.css');


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
