export default {
  getVersion: (state) => {
        return state.version;
      },

  getAllQuestions: (state, getters) => {
    let allQuestions = null;
    let answeredQuestions = [];
    let unansweredQuestions = [];
    let markedQuestions = [];
    allQuestions = _.clone(state.questions, true);
    function sortQuestions_answered() {
      allQuestions.forEach((question) => {
        if (question.isAnswered) {
          answeredQuestions.push(question);
        } else {
          unansweredQuestions.push(question);
        }
      });
    }

    function sortQuestions_starred() {
      allQuestions.forEach((question) => {
        if (question.isStarred) {
          markedQuestions.push(question);
        }
      });
    }
    if (state.question_filter.includes("answered")) {
      sortQuestions_answered();
      allQuestions = answeredQuestions;
    }
    if (state.question_filter.includes("unanswered")) {
      sortQuestions_answered();
      allQuestions = unansweredQuestions;
    }
    if (state.question_filter.includes("starred")) {
      sortQuestions_starred();
      allQuestions = markedQuestions;
    }

    // IF the search field is not empty
    if (state.questionSearchQuery !== "") {
      // THEN search for the term in these fields
      allQuestions = allQuestions.filter((question) =>
        question.inquiry
          .toLowerCase()
          .includes(state.questionSearchQuery.toLowerCase())
      );
    }

    return allQuestions;
  },

  getNextQuestion:
    (state) =>
    ({ action: action, id: id }) => {
      let nextId = action === "prev" ? id - 1 : action === "next" ? id + 1 : -1;
      let questionIndex = state.questions.findIndex(
        (question) => question.id === nextId
      );
      if (questionIndex >= 0) {
        return state.questions[questionIndex];
      } else {
        return state.questions[0];
      }
    },

  getQuestionCheck: (state) => (payload) => {
    let question_index = state.questions.findIndex(
      (question) => question.id === payload.question_id
    );
    let index = state.questions[question_index].answer.options.findIndex(
      (option) => option.optionId === payload.option_id
    );
    return state.questions[question_index].answer.options[index].selected;
  },

  getAllCategories: (state) => {
    let AllCategories = null;
    let abcCategories = [];
    let relevanceCategories = [];
    let markedCategories = [];
    AllCategories = _.clone(state.classification, true);
    function sortArrayABC(sortArray) {
      sortArray = sortArray.sort(function (a, b) {
        var textA = a.inquiry.toUpperCase();
        var textB = b.inquiry.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      return sortArray;
    }

    function sortCategorys_ABC() {
      abcCategories = AllCategories.sort(function (a, b) {
        var textA = a.inquiry.toUpperCase();
        var textB = b.inquiry.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      return abcCategories;
    }

    function sortCategorys_Relevance() {
      // relevanceCategories = AllCategories.sort(function(a, b) {
      //   var textA = a.points;
      //   var textB = b.points;
      //   return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
      // })
      // return relevanceCategories
      relevanceCategories = AllCategories.sort(function (a, b) {
        var textA = (a.answers.length * 100) / a.maxAnswers.length;
        var textB = (b.answers.length * 100) / b.maxAnswers.length;
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
      return relevanceCategories;
    }

    function sortCategorys_Starred() {
      let dummyArray = [];
      AllCategories.forEach((category) => {
        if (category.isStarred) {
          markedCategories.push(category);
        } else {
          dummyArray.push(category);
        }
      });
      let markedAndSorted = sortArrayABC(markedCategories);
      dummyArray = sortArrayABC(dummyArray);
      let concatArray = markedAndSorted.concat(dummyArray);
      return concatArray;
    }

    if (state.categoryFilter.includes("abc")) {
      AllCategories = sortCategorys_ABC();
    }
    if (state.categoryFilter.includes("relevance")) {
      AllCategories = sortCategorys_Relevance();
    }
    if (state.categoryFilter.includes("starred")) {
      AllCategories = sortCategorys_Starred();
    }

    return AllCategories;
  },

  getCategoriesNameID: (state) => {
    let nameIdCategorys = [];
    state.classification.forEach((category) => {
      let NameId = {
        id: category.id,
        name: category.inquiry,
      };
      nameIdCategorys.push(NameId);
    });
    return nameIdCategorys;
  },
  getStarredCategories: (state, getters) => {
    let starredCategorys = getters.getAllCategories;
    let markedCategorys = [];
    starredCategorys.forEach((category) => {
      if (category.isStarred) {
        markedCategorys.push(category);
      }
    });

    return markedCategorys;
  },
  getCategoriesRelevance: (state, getters) => {
    let AllCategories = null;
    let relevanceCategories = [];
    AllCategories = _.clone(state.classification, true);

    relevanceCategories = AllCategories.sort(function (a, b) {
      var textA = (a.answers.length * 100) / a.maxAnswers.length;
      var textB = (b.answers.length * 100) / b.maxAnswers.length;
      return textA > textB ? -1 : textA < textB ? 1 : 0;
    });
    return relevanceCategories;
  },

  getClassificationByName: (state) => (name) => {
    let index = state.classification.findIndex(
      (category) => category.inquiry === name
    );
    return state.classification[index];
  },
  getCountQuestionStarred: (state) => {
    return state.countQuestionStarred;
  },
  getCountQuestionAnswered: (state) => {
    return state.countQuestionAnswered;
  },
  getCountQuestionUnanswered: (state) => {
    return state.questions.length - state.countQuestionAnswered;
  },

  getAllQuestionsCount: (state) => {
    return state.questions.length;
  },

  getQuestionFilterArray: (state) => {
    return state.question_filter;
  },

  getCategoryFilterArray: (state) => {
    return state.categoryFilter;
  },

  getGlossarFilterArray: (state) => {
    return state.glossarFilter;
  },

  getAllTerms: (state) => {
    return state.terms;
  },

  getTermById: (state) => (termid) => {
    return state.terms.find((term) => term.id == termid);
  },
  getTermByName: (state) => (termName) => {
    return state.terms.find((term) => term.term === termName);
  },

  getAllTermCategories: (state) => {
    return state.kb_categories;
  },
  getTermsByGlossarFilter: (state) => {
    let filterTerms = [];
    let typeOfGlossarFilter = typeof state.glossarFilter[0];
    if (typeOfGlossarFilter === "string") {
      let term = state.terms.find(
        (term) => term.term === state.glossarFilter[0]
      );
      filterTerms.push(term);
    } else {
      state.terms.forEach((term) => {
        let isTermInFilter = false;
        term.categoryId.forEach((id) => {
          state.glossarFilter.forEach((filterId) => {
            if (filterId === id) {
              isTermInFilter = true;
            }
          });
        });
        if (isTermInFilter) filterTerms.push(term);
      });
    }
    // IF the search field is not empty
    if (state.glossarSearchQuery !== "") {
      // THEN search for the term in these fields
      filterTerms = filterTerms.filter((term) =>
        term.term.toLowerCase().includes(state.glossarSearchQuery.toLowerCase())
      );
    }

    function sortArrayABC(sortArray) {
      sortArray = sortArray.sort(function (a, b) {
        var textA = a.term.toUpperCase();
        var textB = b.term.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      return sortArray;
    }
    sortArrayABC(filterTerms);
    return filterTerms;
  },
  getCategoriesByTermId: (state) => (term) => {
    let categoryArray = [];
    term.categoryId.forEach((categoryId) => {
      let index = state.kb_categories.findIndex(
        (category) => category.id === categoryId
      );
      categoryArray.push(state.kb_categories[index]);
    });
    return categoryArray;
  },

  getCategoryImplicationsLength: (state) => (category) => {
    return category.implications.length;
  },
  getCategoryImplications_1: (state) => (category) => {
    let implicationsOne = [];
    category.implications.forEach((implication) => {
      if (implication.implication.points === 1) {
        implicationsOne.push(implication);
      }
    });
    return implicationsOne.length;
  },
  getCategoryImplications_2: (state) => (category) => {
    let implicationsTwo = [];
    category.implications.forEach((implication) => {
      if (implication.implication.points === 2) {
        implicationsTwo.push(implication);
      }
    });
    return implicationsTwo.length;
  },
  getQuestionSearchQuery: (state) => {
    return state.questionSearchQuery;
  },
  showCardsInOverview: (state) => {
    return state.showCardsInOverview;
  },
  getShowProfileInOverview: (state) => {
    return state.showProfileInOverview;
  },

  getProjectMeta: (state) => {
    return state.project;
  },
  getProjectReflectionFavorits: (state) => {
    return state.favoritesFilter;
  },
  getProjectReflectionNote: (state) => {
    return state.projectRefletionNote;
  },

  // GETTERS for VALUE REFLECTION
  getAllValues: (state) => {
    return state.valueReflection;
  },
  getAllValuesWithNotes: (state) => {
    let listWithNotes = [];
    state.valueReflection.forEach((value) => {
      if (value.note !== "") {
        listWithNotes.push(value);
      }
    });
    return listWithNotes;
  },

  getAllValuesListTwo: (state) => {
    let listTwo = [];
    state.valueReflection.forEach((value) => {
      if (value.list === 2) {
        listTwo.push(value);
      }
    });
    return listTwo;
  },
  getAllValuesListThree: (state) => {
    let listThree = [];
    state.valueReflection.forEach((value) => {
      if (value.list === 3) {
        listThree.push(value);
      }
    });
    return listThree;
  },
  getAllValuesListOne: (state) => {
    let listOne = [];
    state.valueReflection.forEach((value) => {
      if (value.list === 1) {
        listOne.push(value);
      }
    });
    return listOne;
  },

  getActiveValue: (state) => {
    state.valueReflection.forEach((value) => {
      if (value.isActive) return value;
    });
  },
  getAutonomieValue: (state) => {
    let autonomie = state.valueReflection[0];
    return autonomie;
  },

  getValueCount: (state) => {
    return state.valueReflection.length;
  },

  getShowValueReflection: (state) => {
    return state.showValueReflection;
  },
  getShowProjectReflection: (state) => {
    return state.showProjectReflection;
  },
  oneProjectReflectionQuestionAnswered: (state) => {
    return state.oneProjectReflectionQuestionAnswered;
  },
  getProjectCreated: (state) => {
    return state.projectCreated;
  },
  getProjectCompleted: (state) => {
    return state.projectCompleted;
  },
  getProjectReflection: (state) => {
    return state.projectReflection;
  },

  showTutorialProfile: (state) => {
    return state.showTutorialProfile;
  },
  showTutorialSurvey: (state) => {
    return state.showTutorialSurvey;
  },
  showTutorialValueReflection: (state) => {
    return state.showTutorialValueReflection;
  },
  showTutorialProjectReflection: (state) => {
    return state.showTutorialProjectReflection;
  },

  isProjectReflectionStepFinished: (state) => (stepId) => {
    let step_index = state.projectReflection.findIndex(
      (step) => step.step_id === stepId
    );

    // Returns false, if at least one question is unchecked.
    let step_finish = true;
    state.projectReflection[step_index].reflection_questions.forEach(
      (question) => {
        if (
          question.cat === "none" ||
          state.favoritesFilter.includes(question.cat)
        ) {
          if (!question.checked) {
            step_finish = false;
          }
        }
      }
    );
    return step_finish;
  },

  getValueReflectionItemIsActive: (state) => (item) => {
    let valueItemIndex = state.valueReflection.findIndex(
      (value) => value.id === item.id
    );
    return state.valueReflection[valueItemIndex].isActive;
  },

  getProjectReflection_QuestionCheck: (state) => (payload) => {
    let specificStep = state.projectReflection.filter(
      (obj) => obj.step_id === payload.step_id
    );
    let specificQuestion = specificStep[0].reflection_questions.filter(
      (question) => question.id === payload.question_id
    );
    return specificQuestion[0].checked;
  },

  getProjectFinished: (state) => {
    let finsished =
      state.showProjectReflection &&
      state.showValueReflection &&
      state.showProfileInOverview &&
      state.showCardsInOverview;
    return finsished;
  },
};
